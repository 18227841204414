<template>
    <div>
        <el-dialog
            title="活动关联优惠券"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            top="2vh"
            style="margin-bottom:1vh"
            width="600px">
            <el-table :data="bindData" :highlight-current-row="true" style="width: 100%" max-height="400">
                <el-table-column label="#" type="index" width="55"></el-table-column>
                <el-table-column label="ID" prop='goodsId'></el-table-column>
                <el-table-column label="优惠券名称" prop='name'></el-table-column>
                <el-table-column label="优惠券数量" prop='num'></el-table-column>
            </el-table>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
            };
        },
        props:{
            bindData:{
                default: []
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 10px !important;
		height: 10px !important;
	}
</style>