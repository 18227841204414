<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">活动列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" @click="addCouponPackage" v-if="userInfo.dataPermission == 99 && company.isSystem == 1">添加神券包</el-button>
                <el-button icon="el-icon-plus" type="primary" @click="editActivity()">添加活动</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="活动ID" v-model="pageParam.params.id" />
                <le-input label="活动名称" v-model="pageParam.params.activityTitle" />
                <le-input label="套餐ID" v-model="bindGoodsId" />
                <jd-datetime-range 
                    :large="true" 
                    label="活动时间" 
                    valueFormat="timestamp" 
                    :minDate.sync="pageParam.params.startTime" 
                    :maxDate.sync="pageParam.params.endTime" />
            </le-search-form>
            <le-pagview ref="activityPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.activityPagelist">
                <el-table ref="activityPagelist" class="activityPagelist" :data="tableData" :highlight-current-row="true"  style="width: 100%" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column label="ID" prop="id" min-width="100"></el-table-column>
                    <el-table-column label="名称" prop="activityTitle" min-width="160"></el-table-column>
                    <el-table-column 
                        label="状态"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.status | initDic(statusStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="类型"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs" style="align-items: flex-start">
                                <span>{{ row.activityRewardsType | initDic(activityRewardsTypeDic) }}</span>
                                <span 
                                    class="font-point a-c-blue" 
                                    @click="showBindCoupon(row.rewardsGoods)" 
                                    v-if="row.activityRewardsType == 4">{{ row | initCouponNum }}张 查看</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="条件"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <div class="a-flex-cfsfs">
                                <!-- <div class='a-flex-cfsfs' v-if="row.activityRewardsType == 4">
                                    <span>总量：{{ row.activityTotalTimes == -1 ? '不限' : row.activityTotalTimes }}</span>
                                    <span>用户：{{ row.userTimes == -1 ? '不限' : row.activityTotalTimes }}</span>
                                </div> -->
                                <span>{{ row.userJoinPeriod | initDic(userJoinTimesDic) }}的次数{{ row.userJoinTimes!=-1?row.userJoinTimes:'不限制' }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="内容"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.activityRewardsType | initDic(activityRewardsTypeDic) }}：</span>
                            <span v-if="row.activityRewardsType==0">{{ row.activityRewardsValue?row.activityRewardsValue:'-' }}天</span>
                            <span v-if="row.activityRewardsType==1">{{ row.activityRewardsValue?Number((row.activityRewardsValue/100).toFixed(2)):'-' }}元</span>
                            <span v-if="row.activityRewardsType==2">{{ row.activityRewardsValue?row.activityRewardsValue:'-' }}折</span>
                            <span v-if="row.activityRewardsType==3">退款比例 {{ row.activityRewardsValue?(row.activityRewardsValue*100).toFixed(0):'-' }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="活动规则"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.activityType | initDic(activityTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="关联套餐"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span class="font-point a-c-blue" @click="showBindGoodName(row)">查看</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="开始时间"
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.activityPeriod==0?row.startTime:'长期' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="失效时间"
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.activityPeriod==0?row.endTime:'长期' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="创建时间"
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTime?row.createTime:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="创建人电话"
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.createUser?row.createUser:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="活动说明"
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.description?row.description:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="90" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                <img src="../../assets/icon/edit.png" class="a-wh-16" @click="editActivity(scope.row)"/>
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="活动消耗记录" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="activityConsume(scope.row)"/>
                            </el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                                <el-dropdown placement="bottom-start" @command="handlerOperation">
                                    <img src="../../assets/icon/more-operation.png" class="a-wh-16"/>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item 
                                            v-for="item in optionsMoreOperation" 
                                            :command='item.value+"#"+scope.$index' 
                                            :disabled="(item.value==2 && scope.row.status==2) || (item.value==3 && scope.row.status==1)"
                                            :key="item.value" 
                                            :value="item.value">{{ item.label }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
        <association-package ref="associationPackage" :id="currentActivityId"></association-package>
        <association-coupon ref="associationCoupon" :bindData="bindCoupon"></association-coupon>
    </div>
</template>

<script>
import util from '../../../src/utils/util'
import AssociationCoupon from './child/associationCoupon.vue'
import AssociationPackage from './child/associationPackage.vue'
import { mapState } from 'vuex';
    export default {
        components:{
            AssociationPackage,
            AssociationCoupon
        },
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.listActivity,
                    method: "post",
                    params: {
                        activityRewardsType: '', //活动奖励模式
                        activityTitle: '', //活动名称
                        bindGoodsId: [],// 关联套餐id
                        startTime: '',
                        endTime: '',
                        id: '', //活动id
                    },
                    freshCtrl: 1,
                },
                bindGoodsId: '',//
                optionsMoreOperation: [{
                    label: "删除",
                    value: 1,
                }, {
                    label: "暂停",
                    value: 2,
                }, {
                    label: "启用",
                    value: 3,
                }],
                activityRewardsTypeDic: [],//活动类型字典
                userJoinTimesDic: [],//
                activityTypeDic: [],//活动规则字典
                activityPeriodDic: [],//失效时间类型字典
                statusStatusDic: [],
                bindGoodName: [],//关联的车库套餐
                bindCoupon: [],//关联的优惠券
                currentActivityId: '',//当前选中活动id
            }
        },
        computed:{
            ...mapState({
                company: state => state.company.company,
                userInfo: state => state.user.user,
            })
        },
        filters: {
            initCouponNum (datas) {
                if(datas && datas.rewardsGoods && datas.rewardsGoods.length) {
                    let num = 0
                    for (let index = 0; index < datas.rewardsGoods.length; index++) {
                        const item = datas.rewardsGoods[index];
                        num += item.num
                    }
                    return num || 0
                }else {
                    return 0
                }
            }
        },
        watch:{
            bindGoodsId (val) {
                let value = val?val.replace('，',','):''
                if(value){
                    this.pageParam.params.bindGoodsId = value.split(',')
                }else{
                    this.pageParam.params.bindGoodsId = []
                }
            }
        },
        created () {
            this.$getDic('activityRewardsType').then(res=>{ this.activityRewardsTypeDic = res; })
            this.$getDic('userJoinTimes').then(res=>{ this.userJoinTimesDic = res; })
            this.$getDic('activityType').then(res=>{ this.activityTypeDic = res; })
            this.$getDic('activityPeriod').then(res=>{ this.activityPeriodDic = res; })
            this.$getDic('activityStatus').then(res=>{ this.statusStatusDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },  
        methods: {
            //获取列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.bindGoodsId = ''
                this.pageParam.params = {
                    activityRewardsType: '', //活动奖励模式
                    activityTitle: '', //活动名称
                    bindGoodsId: [],// 关联套餐id
                    startTime: '',
                    endTime: '',
                    id: '', //活动id
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['activityPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },

            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='auditResult'){
                        if(value[key].length==0){
                            this.pageParam.params.auditResult = ''
                        }else{
                            this.pageParam.params.auditResult = value[key][0]
                        }
                    }
                }
                this.handlerSearch()
            },
            editActivity (datas) {
                this.$router.push({
                    path: '/activity/activity-edit',
                    query:{
                        id: datas&&datas.id?datas.id:''
                    }
                })
            },
            addCouponPackage () {
                this.$router.push({
                    path: '/goods/goods-edit',
                })
            },
            handlerOperation (commands) {
                let command = commands.split('#')[0]
                let index = commands.split('#')[1]
                let reqData
                switch (command) {
                    case '1': //删除
                        reqData = {
                            id: this.tableData[index].id,
                            deleted: 1
                        }
                        this.setActivityStatus(reqData,'是否确认删除？')
                        break;
                    case '2': //暂停
                        reqData = {
                            id: this.tableData[index].id,
                            status: 2
                        }
                        this.setActivityStatus(reqData,'是否确认暂停？')
                        break;
                    case '3': //启用
                        reqData = {
                            id: this.tableData[index].id,
                            status: 1
                        }
                        this.setActivityStatus(reqData,'是否确认启用？')
                        break;
                    default:
                        break;
                }
            },
            setActivityStatus (reqData,content) {
                this.$confirm(content,'温馨提示')
                .then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.updateActivityStatus,
                        method: "post",
                        params: reqData
                    }).then(res => {
                        if (res.result.code == 0) {
                            this.$message.success('操作成功')
                            this.pageParam.freshCtrl++;
                        } else {
                            this.$message.error(res.result.message)
                        }
                    }).catch(err =>{

                })
                })
                .catch(_=>{})
                
            },
            showBindGoodName (datas) {
                this.currentActivityId = datas.id
                this.$refs['associationPackage'].dialogVisible = true
            },
            showBindCoupon (datas) {
                if(!datas.length){
                    this.$message.warning('没有关联的优惠券')
                    return
                }
                this.bindCoupon = datas
                this.$refs['associationCoupon'].dialogVisible = true
            },
            // 活动消耗记录
            activityConsume (datas) {
                this.$router.push({
                    path: '/activity/activity-user-list',
                    query:{
                        id: datas&&datas.id?datas.id:''
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.activityPagelist{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
}

/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>