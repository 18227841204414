<template>
    <div>
        <el-dialog
            title="活动关联套餐"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            top="2vh"
            style="margin-bottom:1vh"
            width="1000px">
            <le-select-remote-search 
                label="站点"
                large
                :multiple="false" 
                v-model="stationId" 
                :options="optionsstationIdList" 
                placeholder="请选择站点(可搜索)" />
            <el-table :data="tableData" :highlight-current-row="true" style="width: 100%" max-height="400">
                <el-table-column label="#" type="index" width="55"></el-table-column>
                <el-table-column label="套餐名称">
                    <template slot-scope="{ row }">
                        <span>{{ row.bindGoodName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="站点名称">
                    <template slot-scope="{ row }">
                        <div class="a-flex-rfsc stationTag" v-for="(item,index) in row.stationNames" :key="index">
                            <span>{{ item }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="开始时间">
                    <template slot-scope="{ row }">
                        <span>{{ row.startTime || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="结束时间">
                    <template slot-scope="{ row }">
                        <span>{{ row.endTime || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width='80'>
                    <template slot-scope="{ row }">
                        <span :class="row.status==1?'a-c-green':'a-c-error'">{{ row.status == 1 ? '执行' : '已失效' }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                stationId: '',
                optionsstationIdList: {
                    url: this.$Config.apiUrl.getStationInfoList,
                    method: "post",
                    params: {
                        incomeId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "searchKey",  //incomeDistriId
                    pageSize: 500
                },//站点数据
                tableData: []
            };
        },
        props:{
            id:{
                default: ''
            }
        },
        watch: {
            stationId: {
                handler (val) {
                    this.getActivityGoodsInfo()
                }
            },
            id: {
                handler (val) {
                    this.getActivityGoodsInfo()
                }
            }
        },
        mounted () {
        },
        methods:{
            getActivityGoodsInfo () {
                this.$Axios._post({
                    url: this.$Config.apiUrl.getActivityGoodsInfo,
                    method: "post",
                    params: {
                        activityId: this.id,
                        stationId: this.stationId
                    }
                })
                .then(res=>{
                    if (res.result.code == 0) {
                        this.tableData = res.result.data
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
                .catch(err=>{

                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 10px !important;
		height: 10px !important;
	}
    .stationTag{
        padding: 0px 6px;
        background: #f4f4f5;
        color: #909399;
        border: 1px solid #e9e9eb;
        border-radius: 3px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 12px;
        word-break: keep-all;
    }
</style>